exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-electronic-workshop-js": () => import("./../../../src/pages/electronic-workshop.js" /* webpackChunkName: "component---src-pages-electronic-workshop-js" */),
  "component---src-pages-exhibition-js": () => import("./../../../src/pages/exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laboratory-js": () => import("./../../../src/pages/laboratory.js" /* webpackChunkName: "component---src-pages-laboratory-js" */),
  "component---src-pages-programming-workshop-js": () => import("./../../../src/pages/programming-workshop.js" /* webpackChunkName: "component---src-pages-programming-workshop-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-vr-js": () => import("./../../../src/pages/vr.js" /* webpackChunkName: "component---src-pages-vr-js" */),
  "component---src-pages-webcasting-js": () => import("./../../../src/pages/webcasting.js" /* webpackChunkName: "component---src-pages-webcasting-js" */)
}

